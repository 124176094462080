<template>
    <div>
        <table-tpl-attributes/>
    </div>
</template>

<script>
import TableTplAttributes from '@/components/templates/attributes/table.vue'
export default {
  components: { TableTplAttributes },
    data () {
        return {
           
        }
    }
    
}
</script>